import { LoadingContext } from '@contexts'
import { FC, PropsWithChildren, useState } from 'react'

interface IProps {}

export const LoadingProvider: FC<PropsWithChildren<IProps>> = ({
  children
}) => {
  const [logoutLoading, setLogoutLoading] = useState(false)

  return (
    <LoadingContext.Provider value={{ logoutLoading, setLogoutLoading }}>
      {children}
    </LoadingContext.Provider>
  )
}
