import { createContext } from 'react'

interface IUserContext {
  isLoggedIn: boolean
  performLogin: () => void
  performLogout: () => void
}

export const UserContext = createContext<IUserContext>({
  isLoggedIn: false,
  performLogin: () => {},
  performLogout: () => {}
})
