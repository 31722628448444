import { createContext } from 'react'

interface ILoadingContext {
  logoutLoading: boolean
  setLogoutLoading: (value: boolean) => void
}

export const LoadingContext = createContext<ILoadingContext>({
  logoutLoading: false,
  setLogoutLoading: () => {}
})
