import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { getFormsAPI } from '@api/form'
import { Header } from '@shared'

const Home = () => {
  const [loading, setLoading] = useState(false)
  const [forms, setForms] = useState([])
  const [_page, setPage] = useState(1)
  const [_recordsCount, setRecordsCount] = useState(0)

  useEffect(() => {
    getForms()
  }, [])

  const formItem = (form: any) => {
    if (form.offline) {
      return <Link to={`forms/${form.slug}`} key={form.id} className='text-decoration-none'>
               <div className='ps-3 py-3 rounded-2 border w-100 p-2 shadow-sm mb-3'>{form.title}</div>
             </Link>
    } else {
      return <div className='ps-3 py-3 rounded-2 border w-100 p-2 shadow-sm mb-3' key={form.id}>
        <a href={`${import.meta.env.VITE_API_HOST}/forms/${form.slug}`} className='text-decoration-none' rel='noreferrer'>{form.title}</a>
      </div>
    }
  }

  const renderForms = forms.map((form: any) => (
    formItem(form)
  ))

  const getForms = async() => {
    setLoading(true)
    const result = await getFormsAPI()
    setForms(result.forms)
    setPage(result.pagy.page)
    setRecordsCount(result.pagy.count)
    setLoading(false)
  }

  return (
    <>
      <Header />
      <div className='offset-md-1 col-md-10 mt-3 px-3'>
        { loading ?
          <div className='w-100 text-center'>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
          :
          forms.length > 0 ?
            renderForms
            :
            <div className='text-center text-secondary rounded-2 border w-100 p-2 shadow-sm'>
              You have no forms access yet.
            </div>
        }
      </div>
    </>
  )
}

export default Home
