import { autoSigninAPI } from '@api/session'

export async function autoSignin() {
  const result = await autoSigninAPI()

  if (result.status === 201) {
    localStorage.setItem('profile', JSON.stringify(result.profile))
    return true
  }

  return false
}
