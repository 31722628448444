import { Link } from 'react-router-dom'
import { useContext } from 'react'

import { currentUser } from '@lib'
import { UserContext } from '@contexts'
import { IProps } from './interfases'
import './styles.scss'

const Header = ({
  logo = '',
  showHomeButton = false,
  showSessionButton = true
}: IProps) => {
  const { isLoggedIn, performLogout } = useContext(UserContext)

  const sessionButton = () => {
    return <>
      <div className='dropdown'>
        <button className='btn border btn-sm dropdown-toggle' type='button' data-bs-toggle='dropdown' aria-expanded='false'>
          {currentUser()?.email}
        </button>
        <ul className='dropdown-menu w-100'>
          <li><a className='dropdown-item' onClick={() => performLogout()}>Logout</a></li>
        </ul>
      </div>
    </>
  }

  return (
    <div className='header shadow w-100 d-flex justify-content-between align-items-center'>
      <div className='w-25 d-flex justify-content-start ps-3'>
        {showHomeButton && <Link to='/'>
          <button className='btn btn-outline-primary btn-sm'>Back</button>
        </Link>}
      </div>
      <div className='fs-4 text-center w-50 h-100 d-flex justify-content-center align-items-center'>
        {logo ? <img className='header-img' src={logo} alt='brand' /> : <h2 className='header-img d-flex justify-content-center align-items-center'>Offline Forms</h2>}
      </div>
      <div className='w-25 d-flex justify-content-end pe-3'>
        { showSessionButton && (isLoggedIn && sessionButton())}
      </div>
    </div>
  )
}

export default Header
