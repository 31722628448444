import { useContext, useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { Loader } from '@shared'
import { UserContext } from '@contexts'
import { autoSignin, checkOnlineStatus } from '@lib'

const AutoSignIn = () =>  {
  const { isLoggedIn, performLogin } = useContext(UserContext)
  const [loading, setLoading] = useState(true)

  const { form_id } = useParams()

  useEffect(() => {
    initPage()
  }, [])

  const initPage = async () => {
    await checkOnlineStatus() && await autoSignin() && performLogin()
    setLoading(false)
  }

  return (
      loading ?
      <div className='m-5'>
        <Loader />
      </div>
      :
      isLoggedIn ? <Navigate to={`/forms/${form_id}`} /> : <Navigate to='/login' />
    )

}

export default AutoSignIn
