import { useState, useEffect, lazy, Suspense } from 'react'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

import { getFormAPI } from '@api/form'
import { Loader, NotFoundTemplate} from '@shared'
import { createSubmissionAPI } from '@api/submission'
import { encryptAll } from '@lib'
import { ISerializer } from './interfaces'
import './styles.scss'

const Forms = () => {
  const { id } = useParams()
  const [formId, setFormId] = useState('')
  const [formTemplateId, setFormTemplateId] = useState(null)
  const [loading, setLoading] = useState(true)
  const FormComponent = lazy(() => import(`@templates/${formTemplateId}/index.tsx`).catch(() => ({
    default: () => <NotFoundTemplate />
  })))

  useEffect(() => {
    initPage()
  }, [])

  const initPage = async () => {
    await getForm()
  }

  const getForm = async () => {
    const result = await getFormAPI(id as string)

    if (result.form) {
      setLoading(false)
      setFormId(result.form.id)
      setFormTemplateId(result.form.template_id)
    } else {
      toast.warning(result.message)
    }
  }

  const serializeData = (data: any) => {
    const regexp = /signature_\d/

    let fields: ISerializer = {}
    let signatures: ISerializer = {}

    Object.keys(data).forEach(key => {
      if (key.match(regexp)) {
        signatures[key] = data[key]
      } else {
        fields[key] = data[key]
      }
    })

    return { fields: fields, signatures: signatures }
  }

  const submit = async (data: any) => {
    const serializedData = serializeData(data)
    const encryptedChunks = await encryptAll(JSON.stringify({ fields: serializedData.fields, signatures: serializedData.signatures }))

    try {
      const result = await createSubmissionAPI(formId, encryptedChunks)
      if (result.status === 201) {
        toast.success(result.message)
        return true
      } else {
        toast.error(result.message)
        return false
      }
    } catch (e) {
      toast.warning('You are offline. The form will be submitted in the background once you are back online.', { autoClose: 5000 })
      return true
    }
  }

  return (
    <>
      <Suspense>
        {loading ?
          <div className='m-5'>
            <Loader />
          </div>
          :
          formTemplateId == null ?
            <div className='w-100 fs-3 text-center'>Component not found</div>
            :
            <FormComponent
              submit={submit}
              htmlToPdf={false}
            />
        }
      </Suspense>
    </>
  )
}

export default Forms
