import { useContext } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Loader } from '@shared'
import { Home, Login, Forms, Submissions, AutoSignIn } from '@components'
import { LoadingContext, UserContext } from '@contexts'
import Protected from './protected'

const RoutesComponent = () => {
  const { isLoggedIn } = useContext(UserContext)
  const { logoutLoading } = useContext(LoadingContext)

  return (
    <>
      {logoutLoading ?
        <div className='m-5'>
          <Loader />
        </div>
        :
        <Routes>
          <Route path='*' element={isLoggedIn ? <Navigate to='/' /> : <Navigate to='/login' />} />
          <Route path='/login' element={isLoggedIn ? <Navigate to='/' /> : <Login />} />
          <Route path='/forms/:form_id/submissions/:submission_id' element={<Submissions />} />
          <Route path='/forms/:form_id/auto_sign_in' element={<AutoSignIn />} />

          <Route element={<Protected />}>
            <Route path='/' element={<Home />} />
            <Route path='/forms/:id' element={<Forms />} />
          </Route>
        </Routes>
      }
    </>
  )
}

export default RoutesComponent
