interface CurrentUser {
  email: string
  public_key: string
}

export function currentUser(): CurrentUser {
  return JSON.parse(localStorage.getItem('profile') as string)
}

export function loggedIn(): boolean {
  if (currentUser()?.email) {
    return true
  }

  return false
}
