import { currentUser } from '@lib';

const CHUNK_SIZE: number = 446;

const str2ab = (key: string): ArrayBuffer => {
  const buf = new ArrayBuffer(key.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, keyLen = key.length; i < keyLen; i++) {
    bufView[i] = key.charCodeAt(i);
  }

  return buf;
}

const encode = (data: any): Uint8Array => {
  let enc = new TextEncoder();

  return enc.encode(data);
}

const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
	var binary = '';
	var bytes = new Uint8Array(buffer);
	var len = bytes.byteLength;
	for (var i = 0; i < len; i++) {
		binary += String.fromCharCode(bytes[i]);
	}

	return window.btoa(binary);
}

const importPublicKey = async() => {
  const publicKey = currentUser()?.public_key;
  const binaryDerString = window.atob(publicKey);
  const binaryDer = str2ab(binaryDerString);
  const options = { name: 'RSA-OAEP', hash: 'SHA-256' };

  return window.crypto.subtle.importKey('spki', binaryDer, options, false, ['encrypt']);
}

const encrypt = async(chunk: string) => {
  const encodedData = encode(chunk);
  const encryptionKey = await importPublicKey();
  const ciphertext = await window.crypto.subtle.encrypt(
    {
      name: 'RSA-OAEP'
    },
    encryptionKey,
    encodedData
  );

  return arrayBufferToBase64(ciphertext);
}

export const encryptAll = async(data: string) => {
  let chunks: string[] = []
  let n: number = 0

  while (n < data.length) {
    n += CHUNK_SIZE
    const chunk: string = data.slice(n - CHUNK_SIZE, n)
    const encryptedChunk: string = await encrypt(chunk)
    chunks.push(encryptedChunk)
  }

  return chunks
}
