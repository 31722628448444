import { Link } from 'react-router-dom'

const NotFoundTemplate = () => {
  return (
    <div className='w-100'>
      <div className='text-center text-secondary rounded-2 border p-2 shadow-sm m-4'>
        <div className='fs-4'>
          Template not found
        </div>
        <div className='m-2'>
          <Link to='/' className='btn btn-outline-primary'>
            Go Home
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NotFoundTemplate
