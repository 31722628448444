import { logout } from '@lib'

const headers: Record<string, string> = {
  'Content-Type': 'application/json'
}

export async function requestAPI(endpoint: string, method: string = 'GET', payload: Record<string, any> = {}) {
  const body = method === 'POST' ? JSON.stringify(payload) : null
  const options: RequestInit = {
    method,
    headers,
    body,
    credentials: 'include'
  }
  try {
    const response = await fetch(`${import.meta.env.VITE_API_HOST}/offline_api/${endpoint}`, options)

    if (response.status === 401) {
      return logout()
    } else {
      return { ...await response.json(), status: response.status }
    }
  } catch(e) {
    return { status: 500 }
  }
}
