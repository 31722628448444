import { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { UserContext } from '@contexts'

const Protected = () =>  {
  const { isLoggedIn } = useContext(UserContext)

  return isLoggedIn ? <Outlet /> : <Navigate to='/login' />
}

export default Protected
