import { Provider, ErrorBoundary } from '@rollbar/react'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

import RoutesComponent from './routes'
import { UserProvider, LoadingProvider } from '@providers'

const rollbarConfig = {
  accessToken: import.meta.env.VITE_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: import.meta.env.VITE_ROLLBAR_ENV,
  enabled: import.meta.env.PROD
}

const App = () => {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <ToastContainer
          position='top-right'
          autoClose={2000}
          closeOnClick
          pauseOnHover
          theme='light'/>
        <LoadingProvider>
          <UserProvider>
            <BrowserRouter>
              <RoutesComponent />
            </BrowserRouter>
          </UserProvider>
        </LoadingProvider>
      </ErrorBoundary>
    </Provider>
  )
}

export default App
