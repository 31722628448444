import { toast } from 'react-toastify'

import { destroySessionAPI } from '@api/session'
import { checkOnlineStatus } from '@lib'

export async function logout() {
  if (await checkOnlineStatus()) {
    await destroySessionAPI()
    localStorage.removeItem('profile')
    return true
  } else {
    toast.warning('Get your Internet connection back first.')
    return false
  }
}
