import { useState, useEffect, lazy, Suspense } from 'react'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

import { getSubmissionAPI } from '@api/submission'
import { Loader, NotFoundTemplate } from '@shared'
import './styles.scss'

const Submissions = () => {
  const { form_id, submission_id } = useParams()
  const [formTemplateId, setFormTemplateId] = useState(null)
  const [submissionFields, setSubmissionFields] = useState({})
  const [loading, setLoading] = useState(true)
  const FormComponent = lazy(() => import(`@templates/${formTemplateId}/index.tsx`).catch(() => ({
    default: () => <NotFoundTemplate />
  })))

  useEffect(() => {
    getForm()
  }, [])

  const getForm = async () => {
    const result = await getSubmissionAPI(form_id as string, submission_id as string)
    if (result.status === 200) {
      setLoading(false)
      setFormTemplateId(result.template_id)
      setSubmissionFields({...result.fields, ...result.signatures})
    } else {
      setLoading(false)
      toast.warning(result.message)
    }
  }

  return (
    <>
      <Suspense>
        {loading ?
          <div className='m-5'>
            <Loader />
          </div>
          :
          formTemplateId == null ?
            <div className='w-100 fs-3 text-center'>Component not found</div>
            :
            <FormComponent fields={submissionFields} htmlToPdf={true} />
        }
      </Suspense>
    </>
  )
}

export default Submissions
