import { requestAPI } from '@api/base'

export async function createSessionAPI(payload: Record<string, string>) {
  return await requestAPI('sessions/main', 'POST', { session: payload })
}

export async function autoSigninAPI() {
  return await requestAPI('sessions/auto', 'POST')
}

export async function destroySessionAPI() {
  return await requestAPI('sessions/main', 'DELETE')
}
