import { useContext } from 'react'

import { UserContext, LoadingContext } from '@contexts'
import { FC, PropsWithChildren, useState } from 'react'
import { loggedIn, logout } from '@lib'

interface IProps {}

export const UserProvider: FC<PropsWithChildren<IProps>> = ({
  children
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(loggedIn())
  const { setLogoutLoading } = useContext(LoadingContext)

  const performLogin = () => setIsLoggedIn(true)

  const performLogout = async() => {
    setLogoutLoading(true)
    await logout() && setIsLoggedIn(false)
    setLogoutLoading(false)
  }

  return (
    <UserContext.Provider value={{ isLoggedIn, performLogin, performLogout }}>
      {children}
    </UserContext.Provider>
  )
}
