import { useState, useContext } from 'react'
import { toast } from 'react-toastify'

import { createSessionAPI } from '@api/session'
import { UserContext } from '@contexts'
import { Header } from '@shared'
import './styles.scss'

const Login = () => {
  const { performLogin } = useContext(UserContext)

  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({email: '', password: '', encryption_key: ''})
  const [showPassword, setShowPassword] = useState(false)
  const [showEncryption, setShowEncryption] = useState(false)

  const handleChange = (event: any) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    setLoading(true)
    login()
  }

  const login = async() => {
    const result = await createSessionAPI(formData)

    if (result.status === 201) {
      toast.success(result.message)
      localStorage.setItem('profile', JSON.stringify(result.profile))
      setLoading(false)
      performLogin()
    } else {
      setLoading(false)
      toast.error(result.message)
    }
  }

  return (
    <>
      <Header />
      <div className='px-3'>
        <div className='row mt-3'>
          <div className='offset-md-4 col-md-4'>
            <div className='row'>
              <div className='offset-md-1 col-md-10'>
                <h2 className='text-center'>
                  Login
                </h2>
                <form onSubmit={handleSubmit}>
                  <div className='mb-3'>
                    <label className='form-label'>Email address</label>
                    <input
                      type='email'
                      name='email'
                      value={formData.email}
                      className='form-control mb-2'
                      onChange={handleChange}
                      required
                    />
                    <label className='form-label'>Password</label>
                    <div className='input-group mb-3'>
                      <input
                        name='password'
                        type={showPassword ? 'text' : 'password'}
                        value={formData.password}
                        className='form-control border-end-0'
                        onChange={handleChange}
                        required
                      />
                      <span className='input-group-text bg-white border-start-0 cursor-pointer' onClick={() => setShowPassword(!showPassword)}>
                        <i className={`bi ${showPassword ? 'bi-eye-slash' : 'bi-eye'}`}/>
                      </span>
                    </div>
                    <label className='form-label'>Encryption Key</label>
                    <div className='input-group mb-3'>
                      <input
                        type={showEncryption ? 'text' : 'password'}
                        name='encryption_key'
                        value={formData.encryption_key}
                        className='form-control border-end-0'
                        onChange={handleChange}
                        required
                      />
                      <span className='input-group-text bg-white border-start-0 cursor-pointer' onClick={() => setShowEncryption(!showEncryption)}>
                        <i className={`bi ${showEncryption ? 'bi-eye-slash' : 'bi-eye'}`}/>
                      </span>
                    </div>
                  </div>
                  <div className='text-center'>
                    {loading ?
                      <button type='submit' className='btn btn-primary w-100 login-button-height' disabled>
                        <div className='spinner-border' role='status'>
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </button> :
                      <button type='submit' className='btn btn-primary w-100 login-button-height'>
                        <div className='fs-5'>Login</div>
                      </button>
                    }
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
